.remove-btn {
    margin-right: 0.5rem;
}

.fade-enter {
    opacity: 0;
    -webkit-transition: opacity 2000ms ease-in;
       -moz-transition: opacity 2000ms ease-in;
        -ms-transition: opacity 2000ms ease-in;
         -o-transition: opacity 2000ms ease-in;
            transition: opacity 2000ms ease-in;
}

.fade-enter-active {
    opacity: 1;
}

.btn-hopscotch.btn-secondary {
    background-color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-secondary:hover {
    background-color: #8f1061; 
    border-color: #840e5e
}

.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled).active, 
.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled):active {
    color: #ffff; 
    background-color: #8f1061; 
    border-color: #840e5e
}

.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}

.btn-hopscotch.btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}

.btn-hopscotch.btn-outline-secondary {
    color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-outline-secondary:hover {
    color: #ffff; 
    background-color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-hopscotch.btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: #ffff; 
    background-color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}

#data_table thead th { 
    position: sticky; 
    top: 55px;
    background-color: #ffff; 
    z-index: 1000;
}
